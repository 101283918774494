import React, { useState } from 'react';
import './styles.scss';

// TODO: put this in CAT
const data = [
  {
    title: 'Can I pass accreditation when using circuitGuard?',
    content: 'Facilities with proper documentation, which can be provided, have passed accreditation from all agencies in the USA.'
  },
  {
    title: 'What documentation do I need to use circuitGuard and multiple patient use of the circuit?',
    content: 'We provide a sample Policy and Procedure document that can be personalized for your facility. Generally, this is all that has been requested by inspectors. If additional information such as our FDA clearance, technical studies, etc. can be provided.'
  },
  {
    title: 'Is it legal to use circuitGuard and multiple patient use of the circuit?',
    content: 'Yes, use of the circuitGuard\u{00AE} and multiple patient use of the anesthesia circuit is covered under the Practice of Medicine exemption for the FDA (21 U.S.C. \u{00A7} 396). The POM exemption must meet 4 criteria and circuitGuard use meets all these criteria.'
  },
  {
    title: 'Is circuitGuard cleared by the FDA?',
    content: 'The FDA 510(k) number is K090738. circuitGuard’s FDA clearance is to protect patients and equipment from contamination.'
  },
  {
    title: 'Does circuitGuard\u{00AE} prevent cross-contamination?',
    content: 'circuitGuard\u{00AE} uses 3M Filtrete hydrophobic filter media that is bi-directional and enclosed in a unitized construction housing. The housing has no failure points. circuitGuard\u{00AE} protects the patient from the equipment and the equipment from the patient.'
  },
  {
    title: 'Does circuitGuard protect against TB?',
    content: 'We are one of only two companies that have specific testing against TB. Our efficiency is 99.998% in one direction. Cross contamination would require the organism to pass through two filters on different patients. Recently an undiagnosed TB patient was operated on and circuitGuard was used to protect the patient and the equipment. Because of the TB efficiency, the equipment did not need to be sterilized nor subsequent patients notified of potential exposure.'
  },
  {
    title: 'Is use of the circuitGuard less expensive than our current system?',
    content: 'Of course, otherwise, we would not be doing this. We are generally less than 50% of the current cost of an anesthesia circuit and associated costs involved. Acquisition cost are less and waste expenses are significantly less.'
  },
  {
    title: 'Does circuitGuard reduce turnover time between cases?',
    content: 'Absolutely, the only devices that need to be changed are the circuitGuard and the face mask can be held in the hand instead of changing a bulky anesthesia circuit and CO2 sample lines.'
  },
  {
    title: 'Does circuitGuard reduce storage space needed?',
    content: 'In a box containing 30 anesthesia circuits, we can ship 800 circuitGuards. Our box takes up less space. Shipping uses fossil fuels so there is an environmental benefit also.'
  },
  {
    title: 'Is there an environmental benefit to using circuitGuard?',
    content: 'Reducing the amount of plastics in our waste is important. Using circuitGuard reduces the number of circuits used per OR per day by up to 80% and significantly impacts the volume of waste generated daily. Reduced shipping costs reduces environmental stress as fewer fossil fuels are needed.'
  }

]

export const DropdownList = () => {
  const [expanded, setExpanded] = useState([]);

  const faqClicked = (ind) => {
    let updated = expanded.slice();
    if (expanded.includes(ind)) {
      updated.splice(expanded.indexOf(ind), 1);
    } else {
      updated.push(ind);
    }
    setExpanded(updated);
  }

  return (
    <div className="faq-list">
      {
        data.map((faq, ind) => {
          let open = expanded.includes(ind)
          return (
            <div key={ind} className={`faq-item ${open ? 'open' : 'closed'}`}>
              <button className="faq-title" onClick={() => faqClicked(ind)}>
                <h5>{faq.title}</h5>
                <span className="faq-icon">
                  {open ? '-' : '+'}
                </span>
              </button>
              <div className={`faq-content`} style={{ maxHeight: open ? '200px' : '0px', marginTop: open ? '15px' : '0px' }}>
                <span>{faq.content}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default DropdownList;