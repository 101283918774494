import React from 'react'
import './styles.scss'

export const IFrameVideo = ({ src, title, name }) => {
  return (
    <div className="iframe-video-wrapper">
      <iframe title={title} src={src} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" name={name} frameBorder="0"></iframe>
    </div>
  )
}

export default IFrameVideo